export const TAX_RATE_ROUNDING_PRECISION = 5

/// Rounds a number to an appropriate number of decimal places for a tax rate
/// (TAX_RATE_ROUNDING_PRECISION). Should be used anytime floating-point
/// rounding errors can creep in, such as when subtracting non-integral numbers.
export function roundTaxRate(rate: number) {
  return roundFloatingPoint(rate, TAX_RATE_ROUNDING_PRECISION)
}

export function roundFloatingPoint(num: number, precision: number) {
  return parseFloat(num.toFixed(precision))
}
