import * as React from 'react'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

export const PageLoader = () => {
  return (
    <div className='mt-48'>
      <div className='pin-center'>
        <MerryGoRound />
      </div>
    </div>
  )
}
