import * as React from 'react'
import { CurrentUserContext } from '../contexts/currentUser'
import { ToastUser } from '@local/ec-app'
import { USER_EMAIL_CHANGED, useTopicSubscription } from '@local/publisher'

export function useCurrentUser(): ToastUser {
  const [user, setUser] = React.useState<ToastUser>(
    React.useContext(CurrentUserContext)
  )

  useTopicSubscription(USER_EMAIL_CHANGED, (event) => {
    setUser((oldUser) => {
      if (oldUser.userUuid === event.userUuid) {
        return { ...oldUser, name: event.email }
      } else {
        return oldUser
      }
    })
  })

  return user
}
