import * as React from 'react'
import create, { StoreApi, createStore, useStore } from 'zustand'
import { combine, NamedSet, subscribeWithSelector } from 'zustand/middleware'

type MethodCreator<TInitialState extends unknown, TMethods extends unknown> = (
  set: StoreApi<TInitialState>['setState'] & NamedSet<TInitialState>,
  get: StoreApi<TInitialState>['getState'],
  api: StoreApi<TInitialState>
) => TMethods

const Service = {
  /**
   * A service could contain:
   *
   * - local state: things that you may need to keep track of to visually display something
   * - actions: raw promises that perform (a)sync actions (fetching, mutating etc).
   *      These can then be consumed via react-query or another react lifecycle management library
   *
   * a services should NOT contain:
   *
   * - remote state cache: you should not be syncing remote state to store it locally,
   *      that is a job for react-query or apollo. The cache will be shared across queries as long
   *      as you use the correct cache key.
   */
  createSingleton: <
    TInitialState extends object,
    TMethods extends object = object
  >(
    initialState: TInitialState,
    methods: MethodCreator<TInitialState, TMethods>
  ) => {
    //@ts-ignore - this is FINE but for some reason webpack doesn't like it.
    return create(subscribeWithSelector(combine(initialState, methods)))
  },
  //
  //
  //
  createContextualService: <
    TInitialState extends object,
    TMethods extends object = object
  >(
    initialState: TInitialState,
    methods: MethodCreator<TInitialState, TMethods>
  ) => {
    type State = ExtractState<
      Write<
        StoreApi<Write<TInitialState, TMethods>>,
        StoreSubscribeWithSelector<Write<TInitialState, TMethods>>
      >
    >
    const defaultStore = createStore(
      subscribeWithSelector(combine(initialState, methods))
    )

    const StoreContext = React.createContext(defaultStore)

    return {
      ServiceBoundary: ({
        children,
        initialState
      }: React.PropsWithChildren<{
        initialState: TInitialState
      }>) => (
        <StoreContext.Provider
          value={Service.createSingleton(initialState, methods)}
        >
          {children}
        </StoreContext.Provider>
      ),
      useService<TSelectorResult extends Partial<State>>(
        selector: (state: State) => TSelectorResult
      ) {
        return useStore(React.useContext(StoreContext), selector)
      }
    }
  }
}

//#region types that should be exported but are not
declare type Write<T, U> = Omit<T, keyof U> & U

declare type StoreSubscribeWithSelector<T> = {
  subscribe: {
    (listener: (selectedState: T, previousSelectedState: T) => void): () => void
    <U>(
      selector: (state: T) => U,
      listener: (selectedState: U, previousSelectedState: U) => void,
      options?: {
        equalityFn?: (a: U, b: U) => boolean
        fireImmediately?: boolean
      }
    ): () => void
  }
}
declare type ExtractState<S> = S extends {
  getState: () => infer T
}
  ? T
  : never
//#endregion

export { Service }
