import * as React from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import { IconButton } from '@toasttab/buffet-pui-buttons'
import { CheckCircleSelectedIcon, CopyIcon } from '@toasttab/buffet-pui-icons'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'

export const CopyText = ({
  content,
  copyText = 'Copy restaurant GUID',
  copiedText = 'Restaurant GUID copied!',
  timeout = 4000
}: {
  content: string
  copyText?: string
  copiedText?: string
  timeout?: number
}) => {
  const { mutate, isSuccess, reset } = useMutation(
    () => navigator.clipboard.writeText(content),
    {
      onSuccess() {
        setTimeout(reset, timeout)
      }
    }
  )

  const getContent = React.useCallback(
    () => <div>{isSuccess ? copiedText : copyText}</div>,
    [isSuccess, copiedText, copyText]
  )
  return (
    <Tooltip content={getContent} variant='dark' placement='top'>
      <IconButton
        icon={isSuccess ? <CheckCircleSelectedIcon /> : <CopyIcon />}
        onClick={() => mutate()}
        textClassName={isSuccess ? 'text-success' : undefined}
      />
    </Tooltip>
  )
}
