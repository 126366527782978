import * as React from 'react'
import { Form, Formik, useFormikContext } from 'formik'

import { Button, ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { SubmitButton } from '@toasttab/buffet-pui-forms'
import { AlertModal, Modal } from '@toasttab/buffet-pui-modal'

import { useAppContainerContext } from '@local/ec-app'

import {
  useGetLocationsQuery,
  LocationInput,
  useSaveLocationMappingsMutation
} from '@local/api'

import {
  LocationsForm,
  LocationsMappingSchema,
  MapLocationsFormToCustomerInput,
  useLocationMappingService
} from '../service'

const EditLocationsForm = ({ children }: React.PropsWithChildren<{}>) => {
  const { data, loading } = useGetLocationsQuery()
  const setToConfirming = useLocationMappingService(
    (s) => () => s.transitionTo('confirming')
  )
  const setToReadonly = useLocationMappingService(
    (s) => () => s.transitionTo('readonly')
  )

  if (data === undefined || loading) {
    return null
  }

  const locations = (data?.customer.locations ?? []).map<LocationInput>(
    (x) => ({
      uuid: x.uuid,
      restaurantGuid: x.restaurant?.guid ?? ''
    })
  )

  return (
    <Formik<LocationsForm>
      validationSchema={LocationsMappingSchema}
      initialValues={{ locations, uuid: data?.customer.uuid }}
      onSubmit={setToConfirming}
      onReset={setToReadonly}
      enableReinitialize
    >
      <Form id='locations'>
        <ConfirmationModal />
        {children}
      </Form>
    </Formik>
  )
}

const ConfirmationModal = () => {
  const { containerSelector } = useAppContainerContext()

  const [isConfirming, toReadonly, toEditing] = useLocationMappingService(
    (s) => [
      s.isConfirming(),
      () => s.transitionTo('readonly'),
      () => s.transitionTo('editing')
    ]
  )

  const [mutate, { loading, data }] = useSaveLocationMappingsMutation({
    refetchQueries: 'active',
    onCompleted: toReadonly
  })

  const { values, setSubmitting } = useFormikContext<LocationsForm>()

  const save = () =>
    mutate({
      variables: {
        customerInput: MapLocationsFormToCustomerInput(values)
      }
    })

  return (
    <AlertModal
      parentSelector={containerSelector}
      isOpen={isConfirming}
      onRequestClose={toReadonly}
      /* 
          We want to "stop" the submitting process because 
          the submit buttons rely on isSubmitting to be 
          false to enable to buttons    
        */
      onAfterOpen={() => setSubmitting(false)}
    >
      <Modal.Body>
        Are you sure you want to save this location configuration?
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button onClick={toEditing} variant='secondary' type='button'>
            Cancel
          </Button>
          <SubmitButton isInProgress={loading} onClick={save}>
            Save
          </SubmitButton>
        </ButtonGroup>
      </Modal.Footer>
    </AlertModal>
  )
}

export { EditLocationsForm }
