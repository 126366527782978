export function retryPromise<T>(
  fn: () => Promise<T>,
  attemptsLeft = 5,
  interval = 1000
): Promise<T> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        // Test for the final rejection before the timeout starts, so the
        // user does not have to wait for a rejection.
        if (--attemptsLeft <= 0) {
          reject(error)
          return
        }

        setTimeout(() => {
          retryPromise(fn, attemptsLeft, interval).then(resolve, reject)
        }, interval)
      })
  })
}
