import isFunction from 'lodash/isFunction'

export type Lazy<TValue, TArgs extends unknown[] = unknown[]> =
  | TValue
  | LazyValue<TValue, TArgs>

export type LazyFunction<TValue, TArgs extends unknown[] = unknown[]> =
  | TValue
  | ((...args: TArgs) => TValue)

export class LazyValue<TValue, TArgs extends unknown[] = unknown[]> {
  constructor(readonly fn: (...args: TArgs) => TValue) {}

  resolve(...args: TArgs): TValue {
    return this.fn(...args)
  }

  static create<TValue, TArgs extends unknown[] = unknown[]>(
    fn: (...args: TArgs) => TValue
  ) {
    return new LazyValue<TValue, TArgs>(fn)
  }

  /**
   * Resolves a value or a lazy value produced by a function.
   */
  static resolve<TValue, TArgs extends unknown[] = unknown[]>(
    valueOrFn: Lazy<TValue, TArgs>,
    ...args: TArgs
  ) {
    return valueOrFn instanceof LazyValue
      ? valueOrFn.resolve(...args)
      : valueOrFn
  }

  static resolveFunction<TValue, TArgs extends unknown[] = unknown[]>(
    valueOrFn: LazyFunction<TValue, TArgs>,
    ...args: TArgs
  ) {
    return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn
  }
}
