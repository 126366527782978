import * as React from 'react'
import { Table, Body, Head, HeadingCell, Row } from '@toasttab/buffet-pui-table'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import {
  PageLayout,
  PageHeader,
  HeadingGroup,
  PageActions,
  PageArea
} from '@toasttab/buffet-pui-config-templates'

import { useLocationMappingService } from './service'
import { useGetLocationsQuery } from '@local/api'

import { Actions } from './components/actions'
import { EditLocationsForm } from './components/form'
import { EditTableRow, ReadonlyTableRow } from './components/row'

/**
 * gets the restaurant guid field name in the locations array
 *
 * @param index the index of the locations array
 * @returns the name of the form field
 */
const restaurantGuidFieldFor = (index: number) =>
  `locations.${index}.restaurantGuid`

const View = () => {
  const { data, loading } = useGetLocationsQuery()
  const [isReadonly, isEditing] = useLocationMappingService((x) => [
    x.isReadonly(),
    x.isConfirming() || x.isEditing()
  ])

  if (loading) {
    return (
      <div className='flex justify-center p-12'>
        <MerryGoRound />
      </div>
    )
  }

  if (data?.customer.locations === undefined) {
    return (
      <div className='flex justify-center p-12'>
        Unable to find location mapping configuration
      </div>
    )
  }

  const locations = data.customer.locations ?? []

  return (
    <EditLocationsForm>
      <PageLayout>
        <PageHeader className='header-color-override'>
          <HeadingGroup>
            <div className='type-headline-3'>Locations</div>
          </HeadingGroup>
          <PageActions>
            <Actions />
          </PageActions>
        </PageHeader>
        <PageArea>
          <Table>
            <Head>
              <Row>
                <HeadingCell className='w-1/4'>Location</HeadingCell>
                {isReadonly && (
                  <HeadingCell className='w-1/4'>Status</HeadingCell>
                )}
                {!isReadonly && (
                  <HeadingCell className='w-1/4'>Restaurant GUID</HeadingCell>
                )}
                <HeadingCell className='w-1/4'>POS Name</HeadingCell>
              </Row>
            </Head>
            <Body>
              {locations.map((location, index) =>
                isEditing ? (
                  <EditTableRow
                    location={location}
                    field={restaurantGuidFieldFor(index)}
                    key={location.uuid}
                  />
                ) : (
                  <ReadonlyTableRow
                    location={location}
                    field={restaurantGuidFieldFor(index)}
                    key={location.uuid}
                  />
                )
              )}
            </Body>
          </Table>
        </PageArea>
      </PageLayout>
    </EditLocationsForm>
  )
}

export default View
export const LocationMappingRoute =
  '/mvc/:client/Company/SystemSettings/ToastLocations'
