import * as React from 'react'
import { withEcApplication } from '@local/ec-app'
import { Pages } from '@local/features'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

export default withEcApplication(
  Pages,
  'ec-settings-spa',
  <div className='pin-center'>
    <MerryGoRound />
  </div>
)
