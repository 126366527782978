import * as React from 'react'

export const WindowContext = React.createContext<Window>(window)
export const useWindowProvider = () => React.useContext(WindowContext)
export const useWindowLocation = () => useWindowProvider().location
export const useOrigin = () => useWindowLocation()?.origin

export interface WindowProviderProps {
  window?: Window
}
export const WindowProvider = ({
  children,
  window: _window = window
}: React.PropsWithChildren<WindowProviderProps>) => (
  <WindowContext.Provider value={_window}>{children}</WindowContext.Provider>
)
