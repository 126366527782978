export const isObject = <T = object>(a: any): a is T =>
  typeof a === 'object' && a !== null

/**
 * Create a hash code from a string
 *
 * @param s The string to hash
 */
export const hashString = (s: String): number => {
  let h: number = 0
  for (let i = 0; i < s.length; i++) {
    h = Math.imul(31, h) + s.charCodeAt(i)
  }
  return h
}

/**
 * Check if two objects are equal with null-safety.
 *
 * @param a The first object
 * @param b The second object
 * @returns true if the objects are both null or undefined or a.equals(b) returns true; false otherwise
 */
export const equals = <T extends { equals?: (other: T) => boolean }>(
  a: T | null | undefined,
  b: T | null | undefined
) => {
  if (a === b) {
    return true
  } else if (a === null || a === undefined) {
    return b === null || b === undefined
  } else if (b === null || b === undefined) {
    return false
  } else {
    return Boolean(a.equals?.(b))
  }
}

/**
 * Compares an object shallow-ly or strictly equal.
 * @param a An object to compare to b
 * @param b An object to compare to a
 * @param options Options object
 * @param [options.comparator] Compares a property value of a and b.
 *   The function is provided values and the current key being compared.
 * @param [optoins.keys] A set of keys to compare. If not provided,
 *   All enumerable keys from a will be used.
 */
export const isShallowEqualTo = <T extends object>(
  a: T | null | undefined,
  b: any,
  options: {
    comparator?: (a: T[keyof T], b: any, key: keyof T) => boolean
    keys?: Array<keyof NonNullable<T>>
  } = {}
): b is T => {
  const {
    comparator = (a, b) => equals(a, b),
    keys = a ? (Object.keys(a) as Array<keyof T>) : []
  } = options

  if (equals(a, b)) {
    return true
  }

  if (isObject<T>(a) && isObject<T>(b)) {
    return keys.every((key: keyof NonNullable<T>) =>
      comparator(a[key as keyof T], b[key as keyof T], key as keyof T)
    )
  }

  return false
}

export const Objects = { hashString, equals, isShallowEqualTo, isObject }
