import * as React from 'react'
import { RecoilRoot } from 'recoil'
import { SnackBarProvider } from '@toasttab/buffet-pui-snackbars'
import { PortalProvider } from '@toasttab/ec-layout'

type Props = {
  children: React.ReactNode
}

export const PagesProviders = ({ children }: Props) => {
  return (
    <PortalProvider value='data-pui-2'>
      <RecoilRoot>
        <SnackBarProvider domRoot='ec-settings-spa' isDismissable>
          {children}
        </SnackBarProvider>
      </RecoilRoot>
    </PortalProvider>
  )
}
