import * as Yup from 'yup'
import { gql } from '@apollo/client'
import { Service } from '@local/service'
import {
  Customer,
  CustomerInput,
  LocationInput,
  useGetLocationsQuery
} from '@local/api'

gql`
  query GetLocations {
    customer {
      uuid
      locations {
        uuid
        name
        status {
          code
          message
        }
        restaurant {
          guid
          name
        }
      }
    }
  }
`

gql`
  query GetRestaurant($guid: ID!) {
    restaurant(restaurantGuid: $guid) {
      guid
      name
    }
  }
`

gql`
  mutation SaveLocationMappings($customerInput: CustomerInput!) {
    updateCustomer(customerInput: $customerInput) {
      locations {
        uuid
        name
        status {
          code
          message
        }
        restaurant {
          guid
          name
        }
      }
    }
  }
`

const LocationsMappingSchema = Yup.object().shape({
  locations: Yup.array().of(
    Yup.object({
      uuid: Yup.string().uuid('Must be a valid uuid'),
      restaurantGuid: Yup.string()
        .matches(
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
          'Must be a valid uuid'
        )
        .nullable()
    })
  )
})
type FormStatus = 'readonly' | 'editing' | 'confirming'

const useLocationMappingService = Service.createSingleton(
  {
    formStatus: 'readonly' as FormStatus
  },
  (set, get) => ({
    isConfirming: () => get().formStatus === 'confirming',
    isEditing: () => get().formStatus === 'editing',
    isReadonly: () => get().formStatus === 'readonly',

    transitionTo: (formStatus: FormStatus) => set({ formStatus }),

    getChecklistItems: () => Promise.resolve('hello') //axios call
  })
)

export interface LocationsForm extends CustomerInput {
  locations: Array<LocationInput>
}

const MapLocationsFormToCustomerInput = (
  form: LocationsForm
): CustomerInput => ({
  ...form,
  locations: form.locations.map((location) => ({
    ...location,
    restaurantGuid:
      location.restaurantGuid === '' ? null : location.restaurantGuid
  }))
})

export {
  useLocationMappingService,
  MapLocationsFormToCustomerInput,
  LocationsMappingSchema
}
