import * as React from 'react'
import { ButtonGroup, Button } from '@toasttab/buffet-pui-buttons'
import { CancelButton, SubmitButton } from '@toasttab/buffet-pui-forms'
import { EditIcon } from '@toasttab/buffet-pui-icons'

import { useLocationMappingService } from '../service'

const Actions = () => {
  const [isReadonly, toEditing] = useLocationMappingService((s) => [
    s.isReadonly(),
    () => s.transitionTo('editing')
  ])

  if (isReadonly) {
    return (
      <ButtonGroup>
        <Button iconLeft={<EditIcon />} onClick={toEditing} variant='secondary'>
          Edit
        </Button>
      </ButtonGroup>
    )
  }
  return (
    <ButtonGroup>
      <CancelButton>Cancel</CancelButton>
      <SubmitButton form='locations'>Submit</SubmitButton>
    </ButtonGroup>
  )
}

export { Actions }
