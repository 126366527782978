import * as React from 'react'
import { useFormikContext } from 'formik'

import { Badge } from '@toasttab/buffet-pui-badge'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Cell } from '@toasttab/buffet-pui-table'

import {
  LocationMappingStatus,
  CustomerInput,
  useGetRestaurantQuery
} from '@local/api'
import { CopyIcon } from '@toasttab/buffet-pui-icons'
import { CopyText } from './copy'

const RestaurantInputCell: React.ComponentType<{
  field: string
}> = ({ field }) => (
  <Cell>
    <TextInputField name={field} style={{ minWidth: '225px' }} />
  </Cell>
)

const StatusBadgeCell: React.ComponentType<{
  status: LocationMappingStatus | null
  restaurantGuid?: string
}> = ({ restaurantGuid, status }) => {
  if (status === null) {
    return <Cell />
  }

  let result = null

  switch (status.code) {
    case 'RestaurantGuidConnected':
      result = <Badge color='success'>connected</Badge>
      break
    case 'RestaurantGuidDoubleMapped':
      result = (
        <div className='flex flex-col items-start space-y-2'>
          <Badge color='error'>Duplicate Mapping</Badge>
          <span className='text-secondary type-caption'>
            Already mapped to: {status.message}
          </span>
        </div>
      )
      break
    case 'RestaurantGuidEmpty':
      result = <Badge color='gray'>Not Connected</Badge>
      break
    case 'RestaurantGuidInvalid':
      if (restaurantGuid !== null) {
        result = <Badge color='error'>Invalid Guid</Badge>
      }
      break
    default:
    case 'NoStatus':
  }

  return <Cell>{result}</Cell>
}

const RestaurantNameCell: React.ComponentType<{
  field: string
  initialRestaurantGuid?: string
  initialRestaurantName: string
}> = ({ field, initialRestaurantGuid, initialRestaurantName }) => {
  const { getFieldMeta } = useFormikContext<CustomerInput>()
  const { value: currentRestaurantGuid, error } = getFieldMeta<string | ''>(
    field
  )

  //#region derived state
  const hasNonEmptyRestaurantGuid =
    currentRestaurantGuid !== '' && currentRestaurantGuid !== null

  const hasEmptyRestaurantGuid = !hasNonEmptyRestaurantGuid

  const isRestaurantGuidChanged =
    initialRestaurantGuid !== currentRestaurantGuid

  const hasError = error !== undefined

  const isValid = error === undefined

  const shouldExecuteQuery =
    isRestaurantGuidChanged && hasNonEmptyRestaurantGuid && isValid
  const skipQuery = !shouldExecuteQuery
  //#endregion

  const {
    data: restaurant,
    loading: isLoadingRestaurant,
    error: restaurantQueryError
  } = useGetRestaurantQuery({
    variables: {
      guid: currentRestaurantGuid
    },
    skip: skipQuery
  })

  if (skipQuery && hasNonEmptyRestaurantGuid) {
    return (
      <Cell>
        {initialRestaurantName}
        <div className='-mt-2 float-right'>
          <CopyText content={currentRestaurantGuid} />
        </div>
      </Cell>
    )
  }

  if (isLoadingRestaurant) {
    return (
      <Cell>
        <MerryGoRound size='xxs' />
      </Cell>
    )
  }

  const updatedName = restaurant?.restaurant?.name ?? null
  const didFindRestaurantName = updatedName !== null

  if (restaurantQueryError || hasError || hasEmptyRestaurantGuid) {
    return <Cell />
  }

  if (!didFindRestaurantName) {
    return (
      <Cell>
        <Badge color='warning'>No restaurant found</Badge>
      </Cell>
    )
  }

  return (
    <Cell>
      {updatedName}
      <div className='-mt-2 float-right'>
        <CopyText content={currentRestaurantGuid} />
      </div>
    </Cell>
  )
}

export { RestaurantInputCell, RestaurantNameCell, StatusBadgeCell }
