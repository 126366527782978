/**
 * Strip a string of whitespace. If the resulting string is empty, return null; otherwise return the stripped string
 *
 * @param s The string to strip
 * @returns null if s is null or all-whitespace or the string with its surrounding whitespace removed
 */
const stripToNull = (s: string | null | undefined): string | null => {
  if (s === null || s === undefined) {
    return null
  } else {
    const stripped = s.trim()
    if (stripped.length === 0) {
      return null
    } else {
      return stripped
    }
  }
}

export const Strings = { stripToNull }
