import { safeLazy } from '@local/lazy'

const LazyEmployeeMapping = safeLazy(() => {
  return import(
    /*webpackChunkName: "settings.employee-mapping" */ './employee-mapping'
  )
})

const LazyMissingData = safeLazy(() => {
  return import(
    /*webpackChunkName: "settings.missing-data" */ './missing-data/MissingData'
  )
})

const LazyPOSTips = safeLazy(() => {
  return import(/*webpackChunkName: "settings.tips" */ './pos/tips')
})

const LazyPaycheckDelivery = safeLazy(() => {
  return import(
    /*webpackChunkName: "settings.paycheck-delivery" */ './delivery-paycheck/PaycheckDeliverySettings'
  )
})

const LazyDashboard = safeLazy(() => {
  return import(/*webpackChunkName: "settings.dashboard" */ './dashboard')
})

const LazyCompanySummary = safeLazy(() => {
  return import(
    /*webpackChunkName: "settings.company-summary-profile" */ './company-summary'
  )
})

const LazyEINSetup = safeLazy(() => {
  return import(
    /*webpackChunkName: "settings.ein-setup" */ './ein-setup/EinSetup'
  )
})

const LazyRts = safeLazy(() => {
  return import(/*webpackChunkName: "settings.rts" */ './rts/Rts')
})

const LazyLocationMapping = safeLazy(() => {
  return import(
    /*webpackChunkName: "settings.location-mapping" */ './location-mapping'
  )
})

const LazyJobMappingPage = safeLazy(() => {
  return import(
    /*webpackChunkName: "settings.job-mapping" */ './job-mapping/JobMappingPage'
  )
})

export {
  LazyEmployeeMapping,
  LazyMissingData,
  LazyPOSTips,
  LazyPaycheckDelivery,
  LazyDashboard,
  LazyCompanySummary,
  LazyEINSetup,
  LazyRts,
  LazyLocationMapping,
  LazyJobMappingPage
}
