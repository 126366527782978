import { useEffect } from 'react'
import { useI18n } from '@local/ec-app'

import en from './locales/en-US'
import es from './locales/es'

export const useInternationalizationConfiguration = () => {
  const { addResourceBundle } = useI18n()

  useEffect(() => {
    addResourceBundle('en-US', 'configuration', en)
    addResourceBundle('es', 'configuration', es)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
