import { Topic } from './topic'

interface UserEmailChanged {
  userUuid: string
  email: string
}

/**
 * Notifies that the global configuration has changed and needs to be read again in a SPA.
 * @see `useConfig`
 * @see `useUpdateConfig`
 */
export const CONFIGURATION_CHANGED = new Topic<void>('configurationChanged')
export const USER_EMAIL_CHANGED = new Topic<UserEmailChanged>(
  'userEmailChanged'
)
