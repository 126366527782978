import * as React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import { Portal } from '@toasttab/ec-layout'
import { useInternationalizationConfiguration } from './configuration/useInternationalizationConfiguration'
import { LocationMappingRoute } from './configuration/pages/location-mapping'

import {
  LazyPriorsParallels,
  LazyW4,
  LazyPositions
} from './implementation/pages/implementation-lazy-pages'

import {
  LazyEmployeeMapping,
  LazyMissingData,
  LazyPOSTips,
  LazyPaycheckDelivery,
  LazyDashboard,
  LazyCompanySummary,
  LazyEINSetup,
  LazyRts,
  LazyLocationMapping,
  LazyJobMappingPage
} from './configuration/pages/configuration-lazy-pages'
import { PagesProviders } from './PagesProviders'

export const PayrollApolloClient = new ApolloClient({
  uri: '/payroll/graph',
  cache: new InMemoryCache()
})

export const FederatedApolloClient = new ApolloClient({
  uri: '/graphql',
  cache: new InMemoryCache({
    typePolicies: {
      Ein: {
        keyFields: ['ein', 'id', 'taxName']
      },
      Fein: {
        keyFields: ['tin', 'id', 'name']
      },
      EinState: {
        // EinStates can have the exact same fields, causing Apollo to accidentally overwrite them with each other
        // solution is to use the full state of the sub-eins to distinguish
        keyFields: [['eins']]
      }
    }
  })
})

// Add more Apollo clients for other graphs here, and wrap only the pages that need them.
// Federation will solve this issue by allowing the use of a single graph among all teams,
// but in the meantime, carefully design pages to avoid overlapping concerns so that graphs can be distinct.

export const Pages = () => {
  useInternationalizationConfiguration()
  return (
    <PagesProviders>
      <BrowserRouter>
        <Routes>
          <Route
            path='/mvc/:client/Company/SystemSettings/ToastEmployees'
            element={
              <Portal
                id='ec-app-integrations'
                component={<LazyEmployeeMapping />}
              />
            }
          />
          <Route
            path='/mvc/:companyCode/Company/Import/PriorsParallels'
            element={<LazyPriorsParallels />}
          />
          <Route
            path='/mvc/:companyCode/Company/Import/W4'
            element={<LazyW4 />}
          />

          <Route
            path='/mvc/:companyCode/Company/Import/CustomerPosition'
            element={<LazyPositions />}
          />

          <Route
            path={'/mvc/:client/Company/Setup/MissingData'}
            element={
              <Portal
                id='missing_data_goes_here'
                component={<LazyMissingData />}
              />
            }
          />

          <Route
            path='/mvc/:client/Company/SystemSettings/POSTips'
            element={
              <Portal id='ec-configuration' component={<LazyPOSTips />} />
            }
          />

          <Route
            path={'/mvc/:client/Company/Setup/PaycheckDelivery'}
            element={
              <Portal
                id='paycheck_delivery_goes_here'
                component={<LazyPaycheckDelivery />}
              />
            }
          />

          <Route
            path={'/mvc/:client/Company/Setup/Dashboard'}
            element={
              <ApolloProvider client={PayrollApolloClient}>
                <Portal
                  id='company-setup-dashboard'
                  component={<LazyDashboard />}
                />
              </ApolloProvider>
            }
          />

          <Route
            path={'/:client/Company/Setup/CompanySummary'}
            element={
              <ApolloProvider client={FederatedApolloClient}>
                <Portal id='page-body' component={<LazyCompanySummary />} />
              </ApolloProvider>
            }
          />

          <Route
            path={'/mvc/:client/Company/Setup/TaxAccounts'}
            element={
              <Portal id='ein_setup_goes_here' component={<LazyEINSetup />} />
            }
          />

          <Route
            path={'/mvc/:client/Admin/SiteAdmin/Rts'}
            element={<Portal id='rts_goes_here' component={<LazyRts />} />}
          />
          <Route
            path={LocationMappingRoute}
            element={
              <ApolloProvider client={FederatedApolloClient}>
                <Portal
                  id='location-mapping'
                  component={<LazyLocationMapping />}
                />
              </ApolloProvider>
            }
          />
          <Route
            path='/mvc/:client/Company/SystemSettings/ToastJobs'
            element={
              <Portal
                id='job-mapping'
                component={
                  <ApolloProvider client={FederatedApolloClient}>
                    <LazyJobMappingPage />
                  </ApolloProvider>
                }
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </PagesProviders>
  )
}
