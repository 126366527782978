import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BanquetProps } from 'banquet-runtime-modules'
import * as React from 'react'
import { CookiesProvider } from 'react-cookie'
import { AppContainer } from './AppContainer'
import {
  CurrentUserProvider,
  I18nProvider,
  ConfigProvider,
  TrackingProvider,
  EnvironmentProvider,
  WindowProvider
} from './contexts'

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  }
})

export const withEcApplication =
  (
    Application: React.FC,
    testId: string,
    Fallback: NonNullable<React.ReactNode> = <div></div>,
    tailwindVersion: string = 'data-pui-2'
  ) =>
  (props: Partial<BanquetProps>) => {
    const { name } = props

    return (
      <React.Suspense fallback={Fallback}>
        <QueryClientProvider client={client}>
          <AppContainer
            testId={testId}
            application={name!}
            tailwindVersion={tailwindVersion}
          >
            <WindowProvider>
              <EnvironmentProvider>
                <I18nProvider>
                  <ConfigProvider>
                    <CurrentUserProvider>
                      <TrackingProvider>
                        <CookiesProvider>
                          <Application />
                        </CookiesProvider>
                      </TrackingProvider>
                    </CurrentUserProvider>
                  </ConfigProvider>
                </I18nProvider>
              </EnvironmentProvider>
            </WindowProvider>
          </AppContainer>
        </QueryClientProvider>
      </React.Suspense>
    )
  }
