import * as React from 'react'
import { Cell, Row } from '@toasttab/buffet-pui-table'
import {
  RestaurantInputCell,
  RestaurantNameCell,
  StatusBadgeCell
} from './cell'
import { Location } from '@local/api'

type Props = {
  location: Pick<Location, 'name' | 'status' | 'restaurant'>
  field: string
}

const ReadonlyTableRow: React.FC<Props> = ({ location, field }) => (
  <Row>
    <Cell>{location.name}</Cell>
    <StatusBadgeCell
      status={location.status}
      restaurantGuid={location.restaurant?.guid}
    />
    <RestaurantNameCell
      initialRestaurantName={location.restaurant?.name ?? ''}
      initialRestaurantGuid={location.restaurant?.guid}
      field={field}
    />
  </Row>
)

const EditTableRow: React.FC<Props> = ({ location, field }) => (
  <Row>
    <Cell>{location.name}</Cell>
    <RestaurantInputCell field={field} />
    <RestaurantNameCell
      initialRestaurantName={location.restaurant?.name ?? ''}
      initialRestaurantGuid={location.restaurant?.guid}
      field={field}
    />
  </Row>
)

export { ReadonlyTableRow, EditTableRow }
