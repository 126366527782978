/**
 * Check whether a string is null or consists solely of whitespace.
 *
 * @param stringOrNull The string to check
 * @returns true if stringOrNull is null, undefined, or all whitespace; false otherwise
 */
const isBlank = (stringOrNull: string | null | undefined): boolean => {
  return (
    stringOrNull === null ||
    stringOrNull === undefined ||
    stringOrNull.toString().trim().length === 0
  )
}

/**
 * Assert that a string is either null or does not consist solely of whitespace.
 *
 * @param stringOrNull The string to check
 * @param message A message to include in the thrown [[Error]] if stringOrNull is not null, but blank
 */
const assertNullOrNotBlank = (
  stringOrNull: string | null | undefined,
  message: string
): void => {
  if (stringOrNull != null && isBlank(stringOrNull)) {
    throw Error(message)
  }
}

/**
 * Assert that a string is not null and does not consist solely of whitespace.
 *
 * @param stringOrNull The string to check
 * @param message A message to include in the thrown [[Error]] if stringOrNull is null or consists solely of whitespace
 */
const assertNotBlank = (
  stringOrNull: string | null | undefined,
  message: string
): void => {
  if (isBlank(stringOrNull)) {
    throw Error(message)
  }
}

/**
 * Assert that a value is not null or undefined.
 *
 * @param anyOrNull The value to check
 * @param message A message to include in the thrown [[Error]] if the value is null or undefined
 */
const assertNotNull = (anyOrNull: any, message: string): void => {
  if (anyOrNull === null || anyOrNull === undefined) {
    throw Error(message)
  }
}

const assert = (assertion: () => boolean, message: string): void => {
  if (!assertion()) {
    throw new Error(message)
  }
}

export const Validations = {
  isBlank,
  assertNullOrNotBlank,
  assertNotBlank,
  assertNotNull,
  assert
}
