const enUs = {
  dashboard: {
    header: 'Company setup',
    intro: 'Welcome to your Company Setup Dashboard!',
    introConfig:
      'Here you will be able to view and manage the configuration of your Toast Payroll system.',
    introSummary: 'Go to <0>Company Summary</0> to view your Payroll settings.'
  },
  companySummary: {
    internal: 'Internal',
    someInternal: 'Some internal',
    tooltip: 'Indicates if employees are being paid via this pay group',
    header: 'Company Summary',
    error: 'There was an error loading the company summary.',
    boolean: {
      on: 'On',
      off: 'Off',
      na: 'N/A'
    },
    taxSetup: {
      title: 'Tax setup',
      subtitle:
        'Overview of your Employer Identification Numbers, used to manage and pay taxes to state and local agencies.',
      stateTitle: 'State',
      target: 'contact care',
      stateWithholding: 'Withholding Tax Account',
      stateUnemployment: 'Unemployment Tax Account'
    },
    employeePaySetup: {
      title: 'Employee pay setup',
      subtitle:
        'Pay settings that impact how your employees receive their tips and pay check.',
      timeAndTips: 'Time and tips',
      timesheetFrequency: {
        title: 'Timesheet frequency',
        tooltip:
          'How often employees are required to submit their timesheets or record their work hours'
      },
      tips: {
        title: 'Tips',
        tooltip: 'How tips are calculated and paid to your employees'
      },
      importTimesheet: {
        title: 'Import timesheet earnings weekly',
        tooltip: 'How often employee hours are sent to Payroll from POS'
      },
      blendedOvertime: {
        title: 'Blended overtime',
        tooltip:
          'Combining regular and extra hours worked by an employee to calculate their overtime pay'
      },
      checkPrintingAndDelivery: 'Check printing and delivery',
      paycheckDeliveryMethod: {
        title: 'Paycheck delivery method',
        tooltip:
          'Who is responsible for printing and issuing checks to employees'
      },
      w2DeliveryMethod: {
        title: 'W-2 delivery method',
        tooltip:
          'Who is responsible for printing and delivering W-2 forms to employees'
      },
      checkWritingAccount: {
        title: 'Check writing account',
        tooltip: 'The account used to issue checks to employees'
      }
    },
    historicalPayroll: {
      title: 'Switched to Toast Payroll',
      description:
        'Displays previous and current quarter payroll with tips and taxes. Toast Payroll manages tax filing for the current quarter.',
      badgeWarning:
        'Let your onboarding consultant know if your historical team, payroll and tax info has not been transferred to Toast.',
      badgeConfirmation:
        'Your historical team, payroll and tax info has been transferred to Toast.',
      businessInfo: 'Business info',
      pastQuarters: 'Past quarters',
      currentQuarter: 'Current quarter',
      tooltipPast: 'Displays past payrolls including tips and taxes.',
      tooltipCurrent:
        'Displays payroll amount including tips and taxes for the current quarter.',
      notProvided: 'Not provided'
    },
    profile: {
      title: 'Company Profile',
      customerStatus: 'Status',
      firstPayrollDate: 'First Payroll:',
      numFeins: 'Number of Feins',
      feins: 'List of FEINs',
      numRestaurants: 'Locations:',
      numPayGroups: 'Number of Pay Groups',
      numActiveEmployees: 'Employees:',
      allowedToPost: 'Allowed to Post',
      showMore: 'Show More',
      showLess: 'Show Less',
      isAllowedToPost: {
        yes: 'Yes',
        no: 'No'
      },
      status: {
        // enums are snake case to match values directly from graph
        none: 'N/A',
        active: 'Active',
        suspect: 'Suspect',
        inactive: 'Inactive',
        prospect: 'Prospect',
        new_client_clean_quarter: 'New Client, Clean Quarter',
        new_client_mid_quarter: 'New Client, Mid Quarter',
        demo_company: 'Demo Company',
        conversion: 'Conversion',
        unverified: 'Unverified'
      }
    },
    payroll: {
      title: 'Payroll Settings',
      subtitle: 'Impact ALL payrolls',
      useBlendedOT: 'Blended Overtime',
      payrollPayor: 'Check Writing Account',
      paycheckDelivery: 'Paycheck Delivery Preference',
      customerPrintsW2: 'W2 Delivery Preference',
      payrollPostDeadline: 'Post Deadline',
      matchTimesheetToPayperiod: 'Weekly vs. Period Timesheets',
      importTimesheetEarningsByWeek: 'Import Timesheet Earnings by Week',
      useBlendedOTSubtitle:
        "Blended Overtime (OT) adds the employee's pay for the work week plus all other earnings to calculate a regular rate of pay used to determine the overtime rate for any overtime payments",
      payrollPayorSubtitle:
        'Account used to write checks for employees receiving paper checks',
      paycheckDeliverySubtitle:
        'Preferred delivery method for any employees getting paid via a paper check',
      payrollPostDeadlineSubtitle:
        'Number of days before your check date determining your payroll due date (this cannot be changed)',
      importTimesheetEarningsByWeekSubtitle:
        'Import time entries by week for an accurate calculation of overtime earnings by week',
      payorCustomerAccount: 'Customer Account',
      payorToastAcccount: 'Toast Account',
      deliverySelfWritten: 'Self-Written',
      deliverySelfPrinted: 'Self-Printed',
      deliveryToast: 'Toast Printed',
      matchTimesheetWeekly: 'Weekly',
      matchTimesheetPeriod: 'Period',
      deliveryMethod: {
        // enums are pascal case to match values directly from graph
        Invalid: '',
        NotRecorded: '',
        PayYourOwnWay: 'Self written',
        SelfPrinted: 'Self printed',
        ToastPrinted: 'Toast printed'
      },
      postDeadline: {
        DayOfPayDate: 'Date of Pay Date',
        FiveDaysBefore: 'Five Days Before',
        FourDaysBefore: 'Four Days Before',
        NotAllowedToPost: 'Not Allowed to Post',
        OneDayBefore: 'One Day Before',
        ThreeDaysBefore: 'Three Days Before',
        TwoDaysBefore: 'Two Days Before'
      }
    },
    jobSetup: {
      title: 'Jobs setup',
      subtitle:
        'The jobs you can assign to your employees in Toast Payroll. To update this list, please navigate to Toast Web to edit, add or configure employee jobs.'
    },
    businessSetup: {
      title: 'Business setup',
      subtitle:
        'Overview of your business and its corresponding federal tax ID, including status of its ability to submit payroll.',
      badgeConfirmed: 'CONFIRMED TO PROCESS PAYROLL',
      badgeProhibited: 'CANNOT PROCESS PAYROLL',
      businessInfo: 'Business info',
      employees: 'Employees',
      locations: 'Locations',
      firstPayroll: 'First payroll',
      status: 'Status',
      statusTooltip: 'Indicates if employees are being paid by this business',
      startDate: 'Start date',
      endDate: 'End date',
      endDateSaved: 'Tax end date saved.',
      endDateDeleted: 'Tax end date deleted.',
      endDateClear: 'Clear',
      endDateError: 'Error when saving new end date.',
      lastFilingQuarter: 'Last tax filing quarter: {{quarter}}',
      mutateError: 'An error occurred while saving the end date.'
    },
    integration: {
      title: 'Integration settings',
      subtitle: 'Keeps employee information consistent across POS and Payroll.',
      active: 'Integration Sync',
      activeDescription:
        'Employee information can be transferred from Payroll to POS',
      activeSubtitle:
        'Integration sync enables employee sync from Payroll to POS',
      syncEmployees: 'Employee Mapping',
      syncEmployeesDescription:
        'Employee hours can be send from POS to Payroll',
      syncEmployeesSubtitle:
        'Employee mapping integration enables timesheet sync from POS to Payroll',
      tipsType: 'Tips',
      tipsTypePooled: 'Pooled',
      tipsTypeNormal: 'Normal',
      tipsTypeOff: 'Off'
    },
    payGroup: {
      title: 'Pay group settings',
      subtitle:
        'Employees who are grouped and appear on the same payroll report, are paid at the same frequency and from the same account.',
      payGroup: 'Pay group',
      status: 'Status',
      payFrequency: 'Pay frequency',
      workWeekStart: 'Start day',
      bankAccount: 'Bank account',
      complete: 'Complete',
      missingAccount: 'Missing Account',
      missingSignature: 'Missing Signature',
      active: 'Active',
      inactive: 'Inactive',
      payFrequencyValue: {
        weekly: 'Weekly',
        biweekly: 'Biweekly',
        monthly: 'Monthly',
        semimonthly: 'Semi-monthly',
        quarterly: 'Quarterly',
        semiannual: 'Semiannual',
        annual: 'Annual',
        none: 'None'
      },
      workWeekStartDayValues: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday'
      }
    }
  },
  pos: {
    tips: {
      sections: {
        type: {
          header: 'Which tips do you want to pull from Toast POS?',
          description:
            'These tips will be synced from Toast POS data and transmitted into payroll.',
          options: {
            normal: 'Normal Tips (no pooling or sharing between employees)',
            pooled: 'Pooled Tips',
            none: 'None'
          }
        },
        earnings: {
          note: {
            notPaid:
              '<strong>Note</strong>: This earning will record and <strong>not pay</strong> the employee on payroll. It is assumed the employee has already received these funds',
            paid: '<strong>Note</strong>: This earning will record and <strong>pay</strong> the employee on payroll. It is assumed the employee has not yet received these funds'
          },
          header: 'Which earning code would you like to use for each tip type?',
          tipsType: 'Tips type',
          earningCode: 'Earning code',
          normal: {
            cashTips: {
              text: 'Declared tips',
              help: 'Refers to “Declared Tips” entered by employees at punch out. Most often these are ‘Cash Tips’ left for the employee that have already been taken and need only be recorded/taxed'
            },
            nonCashTips: {
              text: 'Credit card tips',
              help: 'Refers to Credit Card Tips left by guests paying via credit card. Most often these are tips that have not yet been delivered to the employee and need to be recorded for both tax and payment purposes.'
            }
          },
          alert:
            'All tips must be assigned an earning code in order to send pooled tips from Toast POS to Toast Payroll. Failure to assign a tips type to an earning code may result in inaccurate employee paychecks',
          pooled: {
            cashTips: {
              text: 'Declared Cash Tips',
              help: 'Cash tips declared by the employee at the end of a shift'
            },
            nonCashTips: {
              text: 'Credit Card & Other Tips',
              help: 'Tips paid for by a credit card or other payment type'
            },
            gratuityCashTips: {
              text: 'Cash Gratuities',
              help: 'Gratuities paid for by cash'
            },
            gratuityNonCashTips: {
              text: 'Credit Card & Other Gratuities',
              help: 'Gratuities paid for by a credit card or other payment type'
            }
          }
        }
      }
    }
  }
}

export default enUs
type ConfigurationTranslations = typeof enUs

export { ConfigurationTranslations }
