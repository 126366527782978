import * as React from 'react'
import { lazy } from 'react'
import { retryPromise } from '@local/shared-services'
import { PageLoader } from '@local/features/shared/PageLoader'

// retries and suspense wraps a dynamic import

type ReactModule = { default: React.ComponentType<any> }

type ImportFn = () => Promise<ReactModule>

type FullOptions = {
  importFn: ImportFn
  fallback?: React.ReactNode
}

type Options = ImportFn | FullOptions

const DEFAULT_FALLBACK = <PageLoader />

const parseOptions = (options: Options) => {
  if (typeof options === 'function') return { importFn: options }

  return options
}

type NoProps = { [key: string]: never }

function safeLazy<T = NoProps>(options: Options) {
  const { importFn, fallback } = parseOptions(options)

  const LazyComponent = lazy(() => retryPromise(importFn))

  const SafeComponent: React.FC<T> = (props) => {
    return (
      <React.Suspense fallback={fallback || DEFAULT_FALLBACK}>
        <LazyComponent {...props} />
      </React.Suspense>
    )
  }

  return SafeComponent
}

export default safeLazy
