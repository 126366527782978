import * as React from 'react'
import { safeLazy } from '@local/lazy'

const LazyPriorsParallels = safeLazy(() => {
  return import(/*webpackChunkName: "settings.priors" */ './PriorsParallels')
})

const LazyW4 = safeLazy(() => {
  return import(/*webpackChunkName: "settings.w4" */ './W4')
})

const LazyPositions = safeLazy(() => {
  return import(/*webpackChunkName: "settings.positions" */ './Positions')
})

export { LazyPriorsParallels, LazyW4, LazyPositions }
