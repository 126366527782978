import type { ConfigurationTranslations } from './en-US'
const es: ConfigurationTranslations = {
  dashboard: {
    header: 'Configuración de la empresa',
    intro: '¡Bienvenido al panel de configuración de su empresa!',
    introConfig:
      "Aquí podrá ver y administrar la configuración de su sistema 'Toast Payroll.'",
    introSummary:
      'Vaya a <0>Resumen de la empresa</0> para ver su configuración de Payroll.'
  },
  companySummary: {
    internal: 'Interno',
    someInternal: 'Algunos internos',
    tooltip:
      'Indica si a los empleados se les paga a través de este grupo de pago',
    header: 'Sumario de la compañía',
    error: 'Hubo un error al cargar el resumen de la empresa.',
    boolean: {
      on: 'Encendido',
      off: 'Apagado',
      na: 'N/A'
    },
    taxSetup: {
      title: 'Configuración de impuestos',
      subtitle:
        'Descripción general de sus números de identificación de empleador, utilizados para administrar y pagar impuestos a agencias estatales y locales.',
      stateTitle: 'Estado',
      target: 'atención de contacto',
      stateWithholding: 'Cuenta de retención de impuestos',
      stateUnemployment: 'Cuenta de impuestos de desempleo'
    },
    employeePaySetup: {
      title: 'Configuración de pago de empleados',
      subtitle:
        'Configuraciones de pago que afectan la forma en que sus empleados reciben sus propinas y su cheque de pago.',
      timeAndTips: 'Tiempo y consejos',
      timesheetFrequency: {
        title: 'Timesheet frequency',
        tooltip: 'Frecuencia del parte de horas'
      },
      tips: {
        title: 'Consejos',
        tooltip: 'Cómo se calculan y pagan las propinas a sus empleados'
      },
      importTimesheet: {
        title: 'Importar ganancias semanales de la hoja de tiempo',
        tooltip:
          'Con qué frecuencia se envían las horas de los empleados a Nómina desde POS'
      },
      blendedOvertime: {
        title: 'Horas extra combinadas',
        tooltip:
          'Combinar las horas regulares y extras trabajadas por un empleado para calcular su pago de horas extras'
      },
      checkPrintingAndDelivery: 'Impresión y entrega de cheques',
      paycheckDeliveryMethod: {
        title: 'Método de entrega de cheque de pago',
        tooltip:
          'Quién es responsable de imprimir y emitir cheques a los empleados'
      },
      w2DeliveryMethod: {
        title: 'W-2 método de entrega',
        tooltip:
          'Quién es responsable de imprimir y entregar los formularios W-2 a los empleados'
      },
      checkWritingAccount: {
        title: 'Cuenta de emisión de cheques',
        tooltip: 'La cuenta utilizada para emitir cheques a las empleadas.'
      }
    },
    historicalPayroll: {
      title: 'Cambió a Toast Payroll',
      description:
        'Muestra la nómina del trimestre anterior y actual con propinas e impuestos. Toast Payroll gestiona la declaración de impuestos del trimestre actual.',
      badgeConfirmation:
        'Su equipo histórico, información de nómina e impuestos se ha transferido a Toast.',
      badgeWarning:
        'Informe a su asesor de incorporación si su equipo histórico, su información de nómina y de impuestos no se ha transferido a Toast.',
      businessInfo: 'Business info',
      pastQuarters: 'Últimos trimestres',
      currentQuarter: 'Trimestre actual',
      tooltipPast:
        'Muestra nóminas anteriores, incluidas propinas e impuestos.',
      tooltipCurrent:
        'Muestra el monto de la nómina, incluidas propinas e impuestos, para el trimestre actual.',
      notProvided: 'No Provisto'
    },
    profile: {
      title: 'Perfil de la empresa',
      customerStatus: 'Estado del cliente',
      firstPayrollDate: 'Primera Nómina:',
      numFeins: 'Número de FEIN',
      feins: 'Lista de FEIN',
      numRestaurants: 'Ubicaciones:',
      numPayGroups: 'Número de Grupos de Pago',
      numActiveEmployees: 'Empleados:',
      allowedToPost: 'Permitido Publicar',
      showMore: 'Mostrar más',
      showLess: 'Muestra menos',
      isAllowedToPost: {
        yes: 'Si',
        no: 'No'
      },
      status: {
        // enums are snake case to match values directly from graph
        none: 'N/A',
        active: 'Activo',
        suspect: 'Sospechoso',
        inactive: 'Inactivo',
        prospect: 'Perspectiva',
        new_client_clean_quarter: 'Nueva clienta, cuarto limpio',
        new_client_mid_quarter: 'Nueva clienta, medio trimestre',
        demo_company: 'Empresa de demostración',
        conversion: 'Conversión',
        unverified: 'Inconfirmado'
      }
    },
    payroll: {
      title: 'Configuración de nómina',
      subtitle: 'Nómina Impacto TODAS las nóminas',
      useBlendedOT: 'Tiempo extra combinado',
      payrollPayor: 'Cuenta de emisión de cheques',
      paycheckDelivery: 'Preferencia de entrega de cheque de pago',
      customerPrintsW2: 'Preferencia de entrega W2',
      payrollPostDeadline: 'Publicar fecha límite',
      matchTimesheetToPayperiod: 'Hojas de tiempo semanales vs. periódicas',
      importTimesheetEarningsByWeek:
        'Importar ganancias de parte de horas por semanaImportar ganancias de parte de horas por semana',
      useBlendedOTSubtitle:
        'Las horas extra combinadas (OT) agregan el pago del empleado por la semana laboral más todos los demás ingresos para calcular una tasa de pago regular utilizada para determinar la tasa de horas extra para cualquier pago de horas extra',
      payrollPayorSubtitle:
        'Cuenta utilizada para escribir cheques para empleados que reciben cheques en papel',
      paycheckDeliverySubtitle:
        'Método de entrega preferido para los empleados a los que se les paga mediante un cheque en papelk',
      payrollPostDeadlineSubtitle:
        'Número de días antes de la fecha de su cheque que determina la fecha de vencimiento de su nómina (esto no se puede cambiar)',
      importTimesheetEarningsByWeekSubtitle:
        'Importe entradas de tiempo por semana para un cálculo preciso de las ganancias por horas extra por semana',
      payorCustomerAccount: 'Cuenta de cliente',
      payorToastAcccount: 'Cuenta Brindis',
      deliverySelfWritten: 'Auto-escrito',
      deliverySelfPrinted: 'Auto impreso',
      deliveryToast: 'Toast Impreso',
      matchTimesheetWeekly: 'Semanalmente',
      matchTimesheetPeriod: 'Período',
      deliveryMethod: {
        // enums are pascal case to match values directly from graph
        Invalid: '',
        NotRecorded: '',
        PayYourOwnWay: 'Auto escrita',
        SelfPrinted: 'Auto impreso',
        ToastPrinted: 'Toast Impresa'
      },
      postDeadline: {
        DayOfPayDate: 'Fecha de pago Fecha',
        FiveDaysBefore: 'Cinco días antes',
        FourDaysBefore: 'Cuatro dias antes',
        NotAllowedToPost: 'No permitido publicar',
        OneDayBefore: 'Un día antes',
        ThreeDaysBefore: 'Tres dias antes',
        TwoDaysBefore: 'Dos dias antes'
      }
    },
    jobSetup: {
      title: 'Configuración de trabajos',
      subtitle:
        'Los trabajos que puedes asignar a tus empleados en Toast Payroll. Para actualizar esta lista, navegue hasta Toast Web para editar, agregar o configurar trabajos de empleados.'
    },
    businessSetup: {
      title: 'Configuración de negocios',
      subtitle:
        'Descripción general de su empresa y su identificación fiscal federal correspondiente, incluido el estado de su capacidad para presentar la nómina',
      badgeConfirmed: 'CONFIRMADO PARA PROCESAR NÓMINA',
      badgeProhibited: 'NO SE PUEDE PROCESAR NÓMINA',
      businessInfo: 'Información comercial',
      employees: 'Empleados',
      locations: 'Ubicaciones',
      firstPayroll: 'Primera nómina',
      status: 'Estado',
      statusTooltip: 'Indica si esta empresa paga a los empleados',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de finalización',
      endDateSaved: 'Fecha de finalización del impuesto guardada.',
      endDateDeleted: 'Fecha de finalización del impuesto eliminada.',
      endDateClear: 'Borrar',
      endDateError: 'Error al guardar nueva fecha de finalización.',
      lastFilingQuarter:
        'Último trimestre de declaración de impuestos: {{quarter}}',
      mutateError: 'Se produjo un error al guardar la fecha de finalización.'
    },
    integration: {
      title: 'Configuración de integración',
      subtitle:
        'Mantiene la información de los empleados consistente en POS y Nómina.',
      active: 'Sincronización de integración',
      activeDescription:
        'La información del empleado se puede transferir de Nómina a POS',
      activeSubtitle:
        'La sincronización de integración permite la sincronización de empleados desde la nómina hasta el POS',
      syncEmployees: 'Mapeo de empleados',
      syncEmployeesDescription:
        'Las horas de los empleados se pueden enviar desde POS a Nómina',
      syncEmployeesSubtitle:
        'La integración de mapeo de empleados permite la sincronización de hojas de tiempo desde POS a Nómina',
      tipsType: 'Puntas',
      tipsTypePooled: 'Fiestas',
      tipsTypeNormal: 'Normal',
      tipsTypeOff: 'Apagado'
    },
    payGroup: {
      title: 'Configuración del grupo de pago',
      payGroup: 'Grupo de pago',
      subtitle:
        'Los empleados que están agrupados y aparecen en el mismo informe de nómina reciben su pago con la misma frecuencia y desde la misma cuenta.',
      status: 'Estado',
      payFrequency: 'Frecuencia de pago',
      workWeekStart: 'Día de inicio de la semana laboral',
      bankAccount: 'Cuenta bancaria',
      complete: 'Completo',
      missingAccount: 'Cuenta faltante',
      missingSignature: 'Firma faltante',
      active: 'Activo',
      inactive: 'Inactivo',
      payFrequencyValue: {
        weekly: 'Semanalmente',
        biweekly: 'Quincenal',
        monthly: 'Mensualmente',
        semimonthly: 'Medio Mes',
        quarterly: 'Trimestral',
        semiannual: 'Semi Anual',
        annual: 'Anual',
        none: 'Ninguno'
      },
      workWeekStartDayValues: {
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Jueves',
        friday: 'Viernes',
        saturday: 'Sábado',
        sunday: 'Domingo'
      }
    }
  },
  pos: {
    tips: {
      sections: {
        type: {
          header: '¿Qué propinas desea obtener de Toast POS?',
          description:
            'Estas propinas se sincronizarán desde los datos de Toast POS y se transmitirán a la nómina.',
          options: {
            normal:
              'Propinas Normales (sin agrupar o compartir entre empleados)',
            pooled: 'Propinas Compartidas',
            none: 'Ninguno'
          }
        },
        earnings: {
          note: {
            notPaid:
              '<strong>Nota</strong>: Esta ganancia se registrará y <strong>no se pagará</strong> al empleado en la nómina. Se asume que el empleado ya ha recibido estos fondos',
            paid: '<strong>Nota</strong>: Esta ganancia se registrará y <strong>se pagará</strong> al empleado en la nómina. Se asume que el empleado aún no ha recibido estos fondos. '
          },
          header:
            '¿Qué código de ganancia le gustaría usar para cada tipo de propina?',
          tipsType: 'Tips de propina',
          earningCode: 'Código de ganancia',
          normal: {
            cashTips: {
              text: 'Propinas declaradas',
              help: 'Se refiere a las “Propinas declaradas” ingresadas por los empleados al momento de la salida. En la mayoría de los casos, se trata de "propinas en efectivo" que se le dejan al empleado, que ya se han tomado y solo es necesario registrarlas o gravarlas.'
            },
            nonCashTips: {
              text: 'Propinas de tarjetas de crédito',
              help: 'Se refiere a las propinas de tarjetas de crédito que dejan los huéspedes que pagan con tarjeta de crédito. En la mayoría de los casos, se trata de propinas que aún no se han entregado al empleado y deben registrarse tanto para fines fiscales como de pago.'
            }
          },
          alert:
            'A todas las propinas se les debe asignar un código de ganancia para poder enviar propinas agrupadas desde Toast POS a Toast Payroll. No asignar un tipo de propina a un código de ganancias puede resultar en cheques de pago de empleados inexactos',
          pooled: {
            cashTips: {
              text: 'Propinas declaradas en efectivo',
              help: 'Propinas en efectivo declaradas por el empleado al final de un turno'
            },
            nonCashTips: {
              text: 'Tarjeta de crédito u otro tipo de pago',
              help: 'Propinas pagadas con tarjeta de crédito u otro tipo de pago'
            },
            gratuityCashTips: {
              text: 'Propinas en efectivo',
              help: 'Propinas pagadas en efectivo'
            },
            gratuityNonCashTips: {
              text: 'Tarjeta de crédito y otras propinas',
              help: 'Propinas pagadas con tarjeta de crédito u otro tipo de pago'
            }
          }
        }
      }
    }
  }
}

export default es
