import * as React from 'react'
import { AppErrorBoundary } from '../AppErrorBoundary'

type AppContainerProps = {
  children: React.ReactNode
  application: string
  testId: string
  tailwindVersion: string
}

type AppContainerConfig = {
  containerId?: string
  application: string
  containerSelector?: () => HTMLElement
}

export const AppContainerContext = React.createContext<AppContainerConfig>({
  application: ''
})

export const AppContainer = ({
  children,
  application,
  testId,
  tailwindVersion
}: AppContainerProps & JSX.IntrinsicElements['div']) => {
  const twProps = { [tailwindVersion]: true }
  const containerSelector = () => document.getElementById(testId)!
  return (
    <div id={testId} data-testid={testId} {...twProps}>
      <AppContainerContext.Provider
        value={{ containerId: testId, containerSelector, application }}
      >
        <div data-app-container>
          <AppErrorBoundary application={application}>
            {children}
          </AppErrorBoundary>
        </div>
      </AppContainerContext.Provider>
    </div>
  )
}

export const useAppContainerContext = () =>
  React.useContext(AppContainerContext) || {}
