export type EnvironmentLabel = 'prod' | 'qa' | 'dev' | 'local'

export interface EnvironmentConfig {
  label: EnvironmentLabel
  isLocal: boolean
  isProduction: boolean
  isQA: boolean
  isDev: boolean
}

export const _getEnv = (url: string): EnvironmentLabel => {
  if (
    url.indexOf('web.qastratex') > -1 ||
    url.indexOf('preprod.eng.toasttab.com') > -1
  ) {
    return 'qa'
  } else if (url.indexOf('web.devstratex') > -1) {
    return 'dev'
  }

  if (
    url.indexOf('localhost') > -1 ||
    url.indexOf('http://') > -1 ||
    url.indexOf('payroll.eng.toastteam.com') > -1
  ) {
    return 'local'
  }

  return 'prod'
}

const label = _getEnv(window.location.href)

export const environmentConfig: EnvironmentConfig = {
  label,
  isProduction: label === 'prod',
  isQA: label === 'qa',
  isDev: label === 'dev',
  isLocal: label === 'local'
}
